section {
  .locations-container {
    display: flex;
    flex-flow: wrap;
  }

  .locations-row-container {
    margin-top: 45px;
  }
}

.map-locations-page {
  .map {
    float: right;
  }
}
