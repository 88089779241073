.gallery-row {
  width: 80%;
  margin: auto;
}

.provider-gallery-page {
  .gallery-row h4 {
    font-size: 20px !important;
  }

}

.provider-gallery-component {
  .card-body {
    height: 90px;
  }
  .card-title {
    font-size: 19px !important;
    margin-bottom: 10px;
  }
}
