/*CTA */

.cta-dark {
    width: 80%;
    padding: 50px 0 !important;
    font-family: "Enterprise-SansVF", Arial, sans-serif !important;
    background-color: #505152;
    color: #fff;

    p {
        color: #ffffff !important;
    }

    h1 {
        color: #ffffff !important;
    }

    h2 {
        font-size: 42px;
        text-align: center;
        color: #fff;
    }

    h4 {
        font-size: 23px !important;
        margin-top: 15px;
        text-align: center;
        color: #fff;
        letter-spacing: .04rem;
    }

    a:hover {
        text-decoration: none !important;
    }
}

.cta-light {
    height: 340px;
    background-color: var(--color-1) !important;
    color: #000000 !important;
    padding: 50px 0 !important;
    font-family: "Enterprise-SansVF", Arial, sans-serif !important;

    p {
        color: #000 !important;
    }

    h1 {
        color: #000 !important;
    }

    h2 {
        font-size: 42px;
        text-align: center;
        color: #fff;
    }

    h4 {
        font-size: 23px !important;
        margin-top: 15px;
        text-align: center;
        color: #000;
        letter-spacing: .04rem;
    }

    a:hover {
        text-decoration: none !important;
    }

}

.cta-footer {
    margin-top:30px;

    img {
        object-fit: cover;
    }

    .text-div {
        padding: 30px 5% 30px 45px;
    }
}


.cta-container {
    width: 75%;
    margin: auto;
    background-color: #EFEFEE;

    @media only screen and (max-width: 550px) {
        width: 90%;
        padding: 0px 5px;
    }
}
