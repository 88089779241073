header{

  img {
    object-fit: cover;
    height: 100% !important;
  }

  h1 {
    width: 90%;
  }

  p {
    color: #000 !important;
  }

  .left-column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .row {
      width: 100% !important;
      padding-left: 62px;
    }

    .header-breadcrumb{
      position: absolute;
      z-index:10;
      padding-top:20px;


      @media (max-width: 970px) {
       position: relative;
        padding-top: 10px;
        padding-left:20px;
      }
    }

    .left-column-content{
      padding:0 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      @media (max-width: 1280px) {
        margin: auto;
        padding: 20px 20px 20px 12px;
      }

      .header-top-name {
        font-size: 16px !important;
        padding-bottom: 15px !important;
        font-weight: bolder !important;
      }


      h1{
        font-size: 40px!important;
      }
      p{
        font-size: 23px;
        line-height: 28.77px;
        font-family: "Enterprise-SansVF", Arial, sans-serif !important;
        margin-right: -70px;
      }
    }
  }

  @media only screen and (max-width: 550px) {
    .left-column {
      width: 90% !important;
      align-items: start;
      .left-column-content {
        padding-left: 25px;
      }
      .row {
        width: 100% !important;

        p {
          margin-right: 0px;
        }
      }
    }
    img {
      height: 270px !important;
    }
  }
}
